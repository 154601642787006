import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 448.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,448.000000) scale(0.100000,-0.100000)">


<path d="M3485 3824 c-246 -24 -459 -89 -654 -201 -407 -233 -684 -619 -774
-1078 -31 -158 -30 -402 2 -570 26 -133 91 -323 147 -430 190 -364 517 -646
889 -768 402 -132 798 -109 1181 68 73 33 243 140 300 186 299 250 492 572
564 939 28 138 32 374 11 515 -50 333 -204 639 -440 876 -239 239 -521 386
-854 445 -82 14 -302 25 -372 18z m410 -179 c278 -63 519 -197 722 -402 293
-295 445 -748 389 -1153 -47 -339 -183 -611 -421 -845 -263 -259 -608 -398
-985 -398 -236 0 -417 41 -625 143 -394 194 -681 569 -765 1000 -31 155 -30
427 1 559 112 471 402 822 843 1016 70 31 247 81 331 94 91 15 427 5 510 -14z"/>
<path d="M2621 1831 c-37 -37 -22 -92 42 -155 25 -25 23 -66 -4 -66 -12 0 -23
8 -26 20 -8 29 -33 25 -33 -5 0 -55 88 -71 112 -20 15 33 3 69 -37 114 -40 43
-48 91 -16 91 10 0 21 -9 24 -20 6 -25 37 -27 37 -2 0 49 -65 77 -99 43z"/>
<path d="M2757 1844 c-4 -4 -7 -57 -7 -119 0 -98 2 -114 20 -133 28 -30 64
-28 90 5 19 24 20 37 18 137 -2 91 -6 111 -18 111 -12 0 -16 -21 -18 -118 -3
-116 -3 -118 -25 -115 -21 3 -22 9 -27 117 -5 105 -13 135 -33 115z"/>
<path d="M2922 1842 c-11 -7 -12 -36 -8 -132 3 -67 8 -125 11 -128 9 -9 88 -2
101 9 16 13 19 109 4 109 -5 0 -10 9 -10 19 0 11 5 23 10 26 14 9 12 58 -2 77
-15 19 -87 32 -106 20z m73 -72 c0 -28 -4 -35 -20 -35 -13 0 -21 8 -23 23 -5
34 5 54 25 50 13 -2 18 -13 18 -38z m5 -90 c16 -30 2 -72 -24 -68 -34 5 -36
88 -2 88 9 0 20 -9 26 -20z"/>
<path d="M3108 1843 c-21 -5 -42 -56 -33 -83 4 -11 24 -41 46 -66 41 -48 47
-76 16 -82 -12 -3 -20 4 -24 17 -5 21 -43 31 -43 11 0 -51 65 -87 100 -55 41
38 35 75 -25 139 -40 44 -44 79 -7 84 15 2 22 -2 22 -12 0 -9 8 -16 19 -16 25
0 17 35 -12 55 -22 15 -29 16 -59 8z"/>
<path d="M3251 1833 c-20 -17 -22 -26 -19 -128 3 -108 3 -110 31 -125 24 -13
30 -13 55 0 21 12 28 24 30 53 3 32 0 37 -18 37 -11 0 -20 -6 -19 -12 4 -33
-2 -48 -20 -48 -25 0 -27 10 -23 113 3 77 6 87 22 87 12 0 20 -8 22 -22 2 -13
11 -24 21 -26 24 -5 23 42 -3 68 -25 25 -51 25 -79 3z"/>
<path d="M3397 1844 c-4 -4 -7 -67 -7 -140 0 -123 1 -132 18 -127 15 3 19 16
21 56 3 59 3 60 25 51 12 -4 16 -19 16 -54 0 -39 4 -49 20 -53 18 -5 20 0 20
47 0 29 -6 58 -12 64 -10 10 -10 17 0 32 16 26 15 82 0 102 -14 17 -89 34
-101 22z m68 -79 c0 -33 -3 -40 -20 -40 -15 0 -20 7 -21 35 -2 42 3 52 24 48
13 -2 17 -14 17 -43z"/>
<path d="M3557 1844 c-10 -10 -9 -274 1 -274 29 1 33 20 30 145 -3 119 -10
150 -31 129z"/>
<path d="M3638 1844 c-5 -4 -8 -65 -8 -136 l0 -128 49 0 c40 0 53 4 65 22 20
29 20 67 0 97 -12 17 -13 26 -5 34 15 15 14 82 -2 95 -17 14 -90 26 -99 16z
m74 -58 c6 -29 -8 -56 -27 -56 -11 0 -15 12 -15 40 0 33 4 40 19 40 12 0 20
-9 23 -24z m-3 -100 c20 -24 10 -70 -16 -74 -21 -3 -23 1 -23 42 0 48 16 60
39 32z"/>
<path d="M3796 1842 c-3 -3 -6 -63 -6 -134 l0 -128 55 0 c42 0 55 3 55 15 0
10 -11 15 -35 15 -35 0 -35 1 -35 43 0 40 2 43 27 42 18 0 29 6 31 18 3 13 -3
17 -27 17 -29 0 -31 2 -31 40 0 39 1 40 35 40 28 0 35 4 35 19 0 17 -7 20 -49
19 -27 0 -52 -2 -55 -6z"/>
<path d="M4022 1842 c-9 -7 -12 -41 -10 -138 l3 -129 45 3 c67 5 73 15 74 131
1 77 -2 103 -14 114 -19 18 -80 30 -98 19z m73 -132 c0 -95 0 -95 -25 -95 -25
0 -25 1 -27 90 -1 49 1 93 4 98 3 4 15 7 26 5 21 -3 22 -8 22 -98z"/>
<path d="M4214 1842 c-16 -11 -54 -255 -41 -264 15 -9 27 0 27 21 0 13 9 20
29 23 25 4 31 0 36 -19 3 -14 13 -23 24 -23 18 0 18 3 0 103 -10 56 -19 114
-19 128 0 32 -30 49 -56 31z m35 -159 c1 -17 -5 -23 -20 -23 -22 0 -22 11 -2
105 7 29 7 28 14 -15 4 -25 8 -55 8 -67z"/>
<path d="M4338 1843 c-28 -7 -22 -33 7 -33 l25 0 0 -113 c0 -63 3 -116 6 -120
3 -3 12 -3 20 0 11 4 14 31 14 119 0 109 1 114 21 114 14 0 19 5 17 18 -2 13
-14 17 -48 19 -25 0 -53 -1 -62 -4z"/>
<path d="M4500 1826 c-8 -19 -40 -207 -40 -235 0 -6 7 -11 15 -11 8 0 15 9 15
19 0 22 15 29 47 23 16 -3 23 -11 23 -27 0 -17 5 -22 18 -20 20 3 20 10 -4
166 -16 102 -18 107 -41 107 -16 0 -28 -8 -33 -22z m42 -118 c5 -46 4 -48 -19
-48 -18 0 -23 4 -19 18 3 9 8 39 12 67 8 56 18 42 26 -37z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
